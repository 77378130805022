import React from 'react';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ratingChanged, token } from '../../utils/utils';
import { LuUser } from "react-icons/lu";


import { HiOutlineClipboardCopy } from "react-icons/hi";
import ConfidenceScale from '../../utils/confidenceScale';

export default function ChatReply({state, confidenceScore, messagesEndRef, rating, setRating, comment, setComment, conversation, setCallToAction, callToAction, setShowCommentBox, showCommentBox}) {
    const [containsReply, setContainsReply] = useState(false);
    const [messageID, setMessageID] = useState(null);


    useEffect(() => {
        if (conversation?.hasUnansweredRequest()) {
            setContainsReply(false);
        } else {
            setMessageID(getMessageId());
            setContainsReply(true);
        }
    }, [conversation]);
    
    
    function getMessageId() {
        const messageRequests = conversation?.getMessageRequests() || [];
        if (messageRequests.length > 0) {
            return messageRequests[messageRequests.length - 1].id;
        }
        return null;
    }
    
    const rate = (newRating) => {
        setRating(newRating, messageID);
        setShowCommentBox(true);
        ratingChanged(newRating, messageID);
    }

    useEffect(() => {
        scrollToBottom();
    }, [conversation, showCommentBox]);

    function scrollToBottom() {
        const chatReply = document.getElementsByClassName('Chat-Reply')[0];
        chatReply.scrollTo(0, chatReply.scrollHeight);
    }

    const submit = () => {
        setCallToAction(false);
        setShowCommentBox(false);
        const json = JSON.stringify({comment: comment})
        const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID + '/chat/' + messageID;
        axios.patch(url, json, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        })
        .then(() => {
            setShowCommentBox(false);
        })
        .catch(err => {
            console.log(err);
            setShowCommentBox(true);
        });
    }

    function copyToClipboard(e) {
        const responses = document.getElementsByClassName('chatResponse');
        const lastResponse = responses[responses.length - 1].innerHTML;
        e.stopPropagation();
        if (typeof ClipboardItem !== "undefined") {
            // Shiny new Clipboard API, not fully supported in Firefox.
            // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
            const html = new Blob([lastResponse], { type: "text/html" });
            const data = new ClipboardItem({ "text/html": html});
            navigator.clipboard.write([data]);
          } else {
            // Fallback using the deprecated `document.execCommand`.
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
            const cb = e => {
              e.clipboardData.setData("text/html", lastResponse);
              e.preventDefault();
            };
            document.addEventListener("copy", cb);
            document.execCommand("copy");
            document.removeEventListener("copy", cb);
          }

        const hidden = e.target.nextElementSibling;
        hidden.classList.remove('hidden');
        setTimeout(() => {
            hidden.classList.add('hidden');
        }, 1000);
    }
    const last_index = conversation?.getMessageRequests().length - 1;

    return (
        <div className='chat-window'>
            <div className="Chat-Reply">
                {
                    conversation?.getMessageRequests().map((messageRequest, index) => {
                        return (
                            <div key={messageRequest.id} >
                                <div className="chatRequest">
                                    <div className='chatRequest__prompt' >{messageRequest.getPrompt()}</div>
                                    <div className='chatRequest__icon' ><LuUser size={30}/></div>
                                </div>
                                <div className="chatResponse">
                                    <div className='chatResponse__icon' ><img className="sender" src="/robot.png" width={40} height={40} alt="Bot" /></div>
                                    <div className='chatResponse__reply' >
                                        <div ref={messagesEndRef} dangerouslySetInnerHTML={{__html: messageRequest.getReply()}}>
                                        </div>
                                        <div className='rating-container' onClick={() => {setShowCommentBox(!showCommentBox)}}> 
                                            {(!conversation.hasUnansweredRequest()) && messageID && index === last_index &&
                                                <div className='rating'>
                                                    <ReactStars
                                                        count={5}
                                                        value={rating}
                                                        onChange={rate}
                                                        size={24}
                                                        activeColor="#ffd700"
                                                    />
                                                    {callToAction &&<div className="rating-text">Bitte bewerte die Antwort</div>}
                                                </div>
                                                }
                                                {(index + 1 == conversation.getMessageRequests().length) && confidenceScore > 0 && <ConfidenceScale confidence={confidenceScore} />}
                                                {containsReply && (state == "waiting") &&
                                                    <div title='Antwort kopieren' className='copy'>
                                                        <HiOutlineClipboardCopy size={25} onClick={copyToClipboard} />
                                                        <div className='hidden copy-text'>Antwort kopiert!</div>
                                                    </div> 
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                
                {showCommentBox &&
                <div className='comment-box'>
                    <textarea className="comment-box__input" placeholder='Dein Feedback ...' onChange={(e) => setComment(e.target.value)}></textarea>
                    <button className="submit-comment" onClick={submit}>Senden</button>
                </div>}
            </div>
                { state == "processing" &&
                <span className="chat-status-indication">Schreibt...</span>}
                { state == "waiting" &&
                <span className="chat-status-indication">Online</span>}
                { state == "offline" &&
            <span className="chat-status-indication">Offline</span>}
        </div>
    )
}