import axios from "axios";
import Conversation from "../classes/conversation";
import MessageRequest from "../classes/messageRequest";

const appCacheCounter = "1"

export function appName() {
    return "Betriebsratsbot";
}

export function disclaimer() {
    return "Ich bin ein KI Bot und kann Fehler machen. Wichtige Informationen sollten überprüft werden! Dies ist keine Rechtsberatung."
}

export function hasToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key) !== null;
}

export function token() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    return localStorage.getItem(key);
}

export function setToken(token) {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.setItem(key, token);
}

export function setChatVersion(version) {
    const key = "chatVersion"
    localStorage.setItem(key, version);
}

export function getChatVersion() {
    const key = "chatVersion"
    return localStorage.getItem(key);
}

export function setChatV2Default() {
    setChatVersion("v2");
}

export function isChatV2Default() {
    return getChatVersion() === "v2";
}

export function setChatV1Default() {
    setChatVersion("v1");
}

export function removeToken() {
    const key = "token for " + process.env.REACT_APP_BACKEND_URL
    localStorage.removeItem(key);
}

export function cacheConversation(conversation) {
    localStorage.setItem("conversation cache - cache number:" + appCacheCounter, JSON.stringify(conversation));
}

export function clearConversation() {
    localStorage.removeItem("conversation cache - cache number:" + appCacheCounter);
}

export function getConversation() {
    const storageConversation = localStorage.getItem("conversation cache - cache number:" + appCacheCounter);
    if (storageConversation === null) {
        return null;
    }
    const conversation = JSON.parse(storageConversation);
    const newConversation = new Conversation(conversation.id, conversation.title);
    const messageRequests = [];
    for (const messageRequest of conversation.messageRequests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    newConversation.setMessageRequests(messageRequests);
    return newConversation;
}

export function clearCache() {
    clearConversation();
    removeToken();
    clearConversation();
}

function parseConversation(fetchConversation) {
    const conversation = new Conversation(fetchConversation.id);
    const messageRequests = [];
    if (fetchConversation.title) {
        conversation.setTitle(fetchConversation.title);
    }
    if (fetchConversation.created_at) {
        conversation.setCreatedAt(fetchConversation.created_at);
    }
    if (!fetchConversation.message_requests) {
        return conversation;
    }
    for (const messageRequest of fetchConversation.message_requests) {
        const newMessageRequest = new MessageRequest(messageRequest.prompt, messageRequest.id, messageRequest.created_at);
        newMessageRequest.setReply(messageRequest.reply);
        messageRequests.push(newMessageRequest);
    }
    conversation.setMessageRequests(messageRequests);
    return conversation;
}

export async function getConversations(projectId=null, with_messages=false) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.get(url, {
            params: {
                with_messages: with_messages
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversations = response.data.map(parseConversation);
        if (conversations === undefined || conversations === null) { 
            setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig.');
            removeToken();
        }
        return conversations;
    } catch (error) {
        handleError(error);

        // Handle error here
    }
}

export async function getConversationById(id, projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations/' + id;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = parseConversation(response.data);
        return conversation;
    } catch (error) {
        handleError(error);

        // Handle error here
    }
}

export async function createConversation(projectId=null) {
    if (!projectId) {
        projectId = process.env.REACT_APP_PROJECT_ID
    }
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/conversations';
    try {
        const response = await axios.post(url, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        const conversation = new Conversation(response.data.id);
        return conversation;
    } catch (error) {
        handleError(error);
        // Handle error here
    }
}

function handleError(error) {
    if (error?.response?.status === 401 || error?.response?.status === 404) {
        setError('Authentifizierung fehlgeschlagen, das angegebene Token ist nicht gültig. Sie werden in kürze zum Login geleitet.');
        removeToken();
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    } else {
        setError('Der Server ist nicht erreichbar, bitte versuchen Sie es später erneut.');
    }
}

export function round(number, decimals) {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export async function getProject() {
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + process.env.REACT_APP_PROJECT_ID;
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
        return response;
    } catch (error) {
        handleError(error);
        return error.response;
        // Handle error here
    }
}

export async function generateDocument(prompt, conversation_id=null, useBetriebsratsBotSources=true, project_ids=[]) {
    if (useBetriebsratsBotSources) {
        project_ids.push(process.env.REACT_APP_PROJECT_ID)
    }
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/generate_document", JSON.stringify({project_ids: project_ids, prompt: prompt, conversation_id: conversation_id}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            }
        });
    } catch (error) {
        handleError(error);
        return error.response;
    }
    return response;
}


export async function downloadAsDocx(conversationId, message_request_id, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + process.env.REACT_APP_TEMPLATE_PROJECT_ID + "/conversations/" + conversationId + "/message_requests/" + message_request_id + "/generate_docx", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token(),
            },
            responseType: 'blob'
        });
    } catch (error) {
        handleError(error);

        return error.response;
    }
    return response;
}

export function reduceToShortText(text, length=30) {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
}

export function parseDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Tag (immer 2-stellig)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monat (0-basiert, daher +1)
    const year = date.getFullYear(); // Jahr

    // Das Ergebnis im Format TT.MM.JJJJ
    return `${day}.${month}.${year}`;
}

export function ratingChanged(newRating, messageID, projectId = null)  {
    projectId = projectId ? projectId : process.env.REACT_APP_PROJECT_ID
    const json = JSON.stringify({rating: newRating})
    const url = process.env.REACT_APP_BACKEND_URL + '/api/v1/projects/' + projectId + '/chat/' + messageID;
    axios.patch(url, json, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token(),
        }
    })
}

export function clearError() {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'none';
}

export function setError(message) {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'block';
    errorHeader.innerHTML = `
        <span>${message}</span>
        <button id="close-error" style="cursor: pointer;">x</button>
    `;

    const closeButton = document.getElementById('close-error');
    closeButton.addEventListener('click', clearError);
}