import React from 'react';
import { round } from '../utils/utils';

const ConfidenceScale = ({ confidence }) => {
  const scaleWidth = 200;
  const pointerPosition = (confidence) * scaleWidth;

  return (
    <div style={{ width: `${scaleWidth}px`, position: 'relative' }} title={"Meine Antwort wird zu mind. " + round(confidence * 100, 0)+"% aus den Quellen gestützt. Selbst bei hohen Werten, kann ich Fehler machen - Ich bin eine KI."} >
      <div
        style={{
          height: '1.2vh',
          background: 'linear-gradient(to right, red, orange, yellow, green)',
          borderRadius: '1vmin',
          width: `${scaleWidth}px`
        }}
      ></div>
      <div
        style={{
          width: '0.2vw',
          height: '2vh',
          background: 'black',
          position: 'absolute',
          top: '-0.5vh',
          left: `${pointerPosition}px`,
          transform: 'translateX(-50%)',
        }}
      ></div>
    </div>
  );
};

export default ConfidenceScale;
